// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  left: 60px;
  width: calc(100% - 60px);
  height: 100%;
  position: absolute;
  opacity: 0;
  margin: 0 auto;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.about-page .text-zone h1, .contact-page .text-zone h1 {
  font-family: "Coolvetica";
  color: #C2DFEB;
  font-weight: 400;
  margin-top: 0;
  position: relative;
  margin-bottom: 40px;
}
@media only screen and (min-width: 800px) {
  .about-page .text-zone h1, .contact-page .text-zone h1 {
    font-size: 53px;
    margin: 0;
  }
}
@media only screen and (max-width: 800px) {
  .about-page .text-zone h1, .contact-page .text-zone h1 {
    font-size: 30px;
    margin: 0;
  }
}
.about-page .text-zone p, .contact-page .text-zone p {
  font-style: 13px;
  color: #F2E7D9;
  font-family: sans-serif;
  font-weight: 300;
  min-width: fit-content;
  animation: pulse 1s;
}
.about-page .text-zone p:nth-of-type(1), .contact-page .text-zone p:nth-of-type(1) {
  animation-delay: 1.1s;
}
.about-page .text-zone p:nth-of-type(2), .contact-page .text-zone p:nth-of-type(2) {
  animation-delay: 1.4s;
}
.about-page .text-zone p:nth-of-type(3), .contact-page .text-zone p:nth-of-type(3) {
  animation-delay: 1.7s;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/index.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,wBAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,cAAA;EACA,4BAAA;EACA,6BAAA;EACA,mBAAA;AACJ;;AAKQ;EACI,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;AAFZ;AAGY;EAPJ;IAQQ,eAAA;IACA,SAAA;EAAd;AACF;AACY;EAXJ;IAYQ,eAAA;IACA,SAAA;EAEd;AACF;AACQ;EACI,gBAAA;EACA,cAAA;EACA,uBAAA;EACA,gBAAA;EACA,sBAAA;EACA,mBAAA;AACZ;AACY;EACI,qBAAA;AAChB;AAEY;EACI,qBAAA;AAAhB;AAEY;EACI,qBAAA;AAAhB","sourcesContent":[".container {\n    left: 60px;\n    width: calc(100% - 60px);\n    height: 100%;\n    position: absolute;\n    opacity: 0;\n    margin: 0 auto;\n    transform-style: preserve-3d;\n    animation: fadeIn 1s forwards;\n    animation-delay: 1s;\n}\n\n.about-page, .contact-page{\n    .text-zone {\n\n        h1 {\n            font-family: 'Coolvetica';\n            color: #C2DFEB;\n            font-weight: 400;\n            margin-top: 0;\n            position: relative;\n            margin-bottom: 40px;\n            @media only screen and (min-width: 800px) {\n                font-size: 53px;\n                margin: 0;\n            }\n            @media only screen and (max-width: 800px) {\n                font-size: 30px;\n                margin: 0;\n            }\n        }\n\n        p {\n            font-style: 13px;\n            color: #F2E7D9;\n            font-family: sans-serif;\n            font-weight: 300;\n            min-width: fit-content;\n            animation: pulse 1s;\n\n            &:nth-of-type(1){\n                animation-delay: 1.1s\n            }\n\n            &:nth-of-type(2){\n                animation-delay: 1.4s\n            }\n            &:nth-of-type(3){\n                animation-delay: 1.7s\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
